<template>
  <div>
    <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
      <!-- 第一板块 -->
      <div class="banner question_banner infoBanBox">
        <img :src="banners.image" alt="" class="infoBan">
        <div class=" w1200 infoBanCont">
          <div class="plcs" v-if="saitiStatusCont!=''&&saitiStatusCont!=null">
            <div class="txt tion1">
              <p class="fnt_42">{{ saitiStatusCont.title }}</p>
            </div>
            <div class="tion2">
              <p class="fnt_18" v-for="(val, index) in saitiStatusCont.mark" :key="index">{{ val }}</p>
            </div>
            <div class="tion3">
              <ul class="box_tion3">
                <li v-if="saitiStatusCont.bonus!=''&&saitiStatusCont.bonus!=null">
                  <p class="fnt_28">{{ saitiStatusCont.bonus }}</p>
                  <span class="fnt_16"><img src="@/assets/images/stxq_img1.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_bonus }}</span>
                </li>
                <li>
                  <p class="fnt_28" v-if="saitiStatusCont.teams!=null&&saitiStatusCont.teams!=''">{{ saitiStatusCont.teams }}</p>
                  <p class="fnt_28" v-else>0</p>
                  <span class="fnt_16"><img src="@/assets/images/stxq_img2.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_teams }}</span>
                </li>
                <li v-if="saitiStatusCont.type_dictText!=''&&saitiStatusCont.type_dictText!=null">
                  <p class="fnt_28">{{ saitiStatusCont.type_dictText }}</p>
                  <span class="fnt_16"><img src="@/assets/images/stxq_img3.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_type }}</span>
                </li>

                <li v-if="saitiStatusCont.signup!=''&&saitiStatusCont.signup!=null">
                  <p class="fnt_28">
                    <!-- 已结束 -->
                    <template v-if="saitiStatusCont.signup=='04'">
                      {{ saitiStatusCont.signup_dictText }}
                    </template>
                    <template v-else>
                      <!-- 未开始跟进行中 -->
                      <template v-if="saitiStatusCont.signup=='01' || saitiStatusCont.signup=='02'">
                        {{ saitiStatusCont.signup_dictText }}
                      </template>

                      <!-- 报名中 -->
                      <template v-if="saitiStatusCont.signup=='03' && currentTime < saitiStatusCont.localEndtimeStr">
                        {{ saitiStatusCont.signup_dictText }}
                      </template>

                      <!-- 截止报名 -->
                      <template v-if="saitiStatusCont.signup=='03' && currentTime >= saitiStatusCont.localEndtimeStr">
                        {{ globalDataArray.siteTemplateLanguage.competition_signup_end }}
                      </template>
                    </template>
                  </p>
                  <span class="fnt_16"><img src="@/assets/images/stxq_img4.png" alt="">{{ globalDataArray.siteTemplateLanguage.competition_state }}</span>
                </li>
              </ul>
            </div>
            <div class="tion4" v-if="saitiStatusCont.signup!=''&&saitiStatusCont.signup!=null">
              <!-- 已结束 -->
              <template v-if="saitiStatusCont.signup=='04'">
                <div class="tion4bm ended"><span>{{ saitiStatusCont.signup_dictText }}</span></div>
              </template>
              <template v-else>
                <!-- 已经报名显示已报名 -->
                <template v-if="saitiStatusCont.isSignup == 1">
                  <div class="tion4bm started"><span>{{ globalDataArray.siteTemplateLanguage.competition_signup_yes }}</span> </div>
                </template>

                <template v-else-if="saitiStatusCont.isSignup == 2">
                  <!--申请中-->
                  <div class="tion4bm started">
                    <span>
                      {{ globalDataArray.siteTemplateLanguage.competition_signup_ing }}
                    </span>
                  </div>
                </template>

                <template v-else>
                  <template v-if="currentTime < saitiStatusCont.localEndtimeStr">
                    <!-- 未开始跟进行中 -->
                    <template v-if="saitiStatusCont.signup=='01' || saitiStatusCont.signup=='02'">
                      <div class="tion4bm started"><span>{{ saitiStatusCont.signup_dictText }}</span> </div>
                    </template>

                    <!-- 报名中 -->
                    <template v-if="saitiStatusCont.signup=='03'">
                      <div class="tion4bm">
                        <span @click="toSignFun(saitiStatusCont.templateId,saitiStatusCont.categoryId,saitiStatusCont.parentId,saitiStatusCont.teamsUsers,saitiStatusCont.teams,saitiStatusCont.parentId,saitiStatusCont.zoneId)">
                          {{ globalDataArray.siteTemplateLanguage.click_signup }}
                        </span>
                      </div>
                    </template>
                  </template>

                  <!-- 截止报名 -->
                  <template v-else>
                    <div class="tion4bm ove"><span>{{ globalDataArray.siteTemplateLanguage.competition_signup_end }}</span> </div>
                  </template>
                </template>
              </template>

              <div class="tion4_txt fnt_16" v-if="saitiStatusCont.organizersLogo!='' || saitiStatusCont.organizers!=''">
                <div class="gjfTit">{{ globalDataArray.siteTemplateLanguage.competition_co_builder }}:</div>
                <div class="logo">
                  <template v-if="saitiStatusCont.organizersLogo">
                    <span class="tion4span img" v-for="(val, index) in saitiStatusCont.organizersLogo" :key="index"><img :src="val" alt=""></span>
                  </template>
                  <template v-else>
                    <span class="tion4span txt" v-for="(val, index) in saitiStatusCont.organizers" :title="val" :key="index">{{ val }}</span>
                  </template>
                </div>
              </div>
            </div>
            <div class="tion5">
              <p class="tion5_mintop fnt_16" v-if="saitiStatusCont.localStarttime!=''&&saitiStatusCont.localStarttime!=null">
                <img src="@/assets/images/stxq_ks.png" alt="">
                {{ globalDataArray.siteTemplateLanguage.competition_start_signup }}： <span>{{ saitiStatusCont.localStarttime }} UTC+8</span>
              </p>
              <p class="tion5_mintop fnt_16" v-if="saitiStatusCont.localEndtime!=''&&saitiStatusCont.localEndtime!=null">
                <img src="@/assets/images/stxq_js.png" alt="">
                {{ globalDataArray.siteTemplateLanguage.competition_end_signup }}：<span>{{ saitiStatusCont.localEndtime }} UTC+8</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 第二板块 -->
      <div class="question" v-if="competition.progress!=''&&competition.progress!=null">
        <div class="competition w1200">
          <div class="process" v-for="(item,index) in competition.progress" :class="item.progressStatus == 0 ? 'on' : item.progressStatus == 1 ? 'active' : ''" :key="index">
            <p class="txtp">{{ item.title }}</p>
            <p class="time">{{ item.startDate }}</p>
          </div>
        </div>
      </div>

      <!-- 第三板块 -->
      <div class="contenta">
        <div class="w1200">
          <div class="content_mine" v-if="info">
            <div class="content_left">
              <ul>
                <template v-for="(item,index) in competition.info">
                  <li class="fnt_20 " :class="index == event ? 'actire' : ''" :key="index"><a href="javascript:;" @click="changeEvent(index)">{{ item.title }}</a></li>
                </template>
              </ul>
            </div>
            <div class="content_right">
              <div class="content_st" v-for="(item,index) in competition.info" v-show="event==index" :key="index">
                <h3 class="fnt_20">{{ item.title }}</h3>
                <div class="txt ck-content" v-html="item.content">
                </div>
              </div>
            </div>
          </div>
          <div style="text-align:center;" v-else>{{ globalDataArray.siteTemplateLanguage.system_data_empty_tips }}</div>
        </div>
      </div>
    </div>
    <div class="gotopBtn" @click="goTopFun"></div>

    <!-- 选择报名方式弹窗 -->
    <signway :signway.sync="signway" :cur-info-array.sync="curInfoArray" @itemclick="signtypeFun" />

    <!-- 报名弹窗 -->
    <formsign :formsign.sync="formsign" :cur-info-array.sync="curInfoArray" @itemclick="formsignFun" />   <!--成人-->

    <formteen :formteen.sync="formteen" :cur-info-array.sync="curInfoArray" @itemclick="formsignFun" />   <!--青年-->

    <!-- 加入团队列表 -->
    <formapply :formapply.sync="formapply" :cur-info-array.sync="curInfoArray" @itemclick="formapplyFun" />

    <!-- 加入团队信息填写弹窗 -->
    <formmsg :formmsg.sync="formmsg" :cur-info-array.sync="curInfoArray" :team-array.sync="teamArray" @itemclick="formmsgFun" />

    <!-- 参赛用户服务协议 -->
    <formxieyi :formxieyi.sync="formxieyi" :cur-info-array.sync="curInfoArray" @itemclick="formxieyiFun" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { stringToDateTime } from '@/utils/util'
import { getCompetitionInfo } from '@/api/saiti'
import { getAdList } from '@/api/api'
import { message } from 'ant-design-vue'
export default {
  name: 'Sitiinfo',
  asyncData({ store, route }) {
    return store.dispatch('getSaitiInfo', { id: route.query.id })
  },
  computed: {
    contentInfo() {
      return this.$store.state.sitiInfo
    }
  },
  data() {
    return {
      banners: [],
      competition: {},
      currentTime: new Date().getTime(), // 当前地区时区时间
      event: 0,
      info: {},

      signway: false,
      signtype: '',

      formsign: false,
      formteen: false,
      formen: false,
      formenteen: false,

      formapply: false,

      formmsg: false,

      curInfoArray: {
        categoryId: '', // 当前赛题分类id
        competitionId: '', // 当前赛题id
        templateId: '', // 报名模板
        currentLanguge: '',
        teamsUsers: '',
        teams: '',
        parentId: '',
        zoneId: ''
      },
      teamArray: {},
      formxieyi: false,

      saitiStatusCont: ''
    }
  },
  created() {

  },
  mounted() {
    this.init()

    if (typeof document === 'object') {
      document.documentElement.scrollTop = 0
    }
  },
  methods: {
    init() {
      const that = this
      const adParam = 'alias=newsinfo_banner'
      getAdList(adParam).then(res => {
        if (res.success) {
          if (res.result.length > 0) {
            that.banners = res.result[0]
          }
        }
      })
      // that.getNowDate()//获取当前时间
      that.getCompetitionInfo()
    },
    changeEvent(e) {
      const that = this
      that.event = e
      // that.info = that.competition.info[that.event]
    },
    getCompetitionInfo() {
      const that = this
      const param = 'id=' + this.$route.query.id

      if (that.$store.state.sitiInfo == null || that.$store.state.sitiInfo == '') {
        message.error(that.globalDataArray.siteTemplateLanguage.system_data_empty_tips)

        let backPath = '/competition'
        if (this.$route.query.from) {
          backPath = '/competition?from=' + this.$route.query.from
        }

        this.$router.push({ path: backPath })
        return
      }

      // 获取banner上的状态
      getCompetitionInfo(param).then(res => {
        if (res.success) {
          // console.log(res)
          that.saitiStatusCont = res.result

          // 将utc时间转为当地时间并作比较
          const endUtcTime = that.saitiStatusCont.endUtcTime
          const localEndtimeStr = new Date(endUtcTime).getTime()
          that.saitiStatusCont['localEndtime'] = stringToDateTime(localEndtimeStr)

          that.saitiStatusCont['localEndtimeStr'] = localEndtimeStr

          const startUtcTime = that.saitiStatusCont.startUtcTime
          const localStarttimeStr = new Date(startUtcTime).getTime()
          that.saitiStatusCont['localStarttime'] = stringToDateTime(localStarttimeStr)

          if (that.saitiStatusCont.mark) {
            const mark = that.saitiStatusCont.mark
            that.saitiStatusCont.mark = mark.split(',')
          }

          if (that.saitiStatusCont.organizersLogo) {
            const organizersLogo = that.saitiStatusCont.organizersLogo
            that.saitiStatusCont.organizersLogo = organizersLogo.split(',')
          }

          if (that.saitiStatusCont.organizers) {
            const organizers = that.saitiStatusCont.organizers
            that.saitiStatusCont.organizers = organizers.split(',')
          }
        }
      })

      that.competition = that.$store.state.sitiInfo
      // that.competition= res.result
      // console.log(that.competition)
      that.info = that.competition.info?.[that.event]

      // 筛选三种状态  过去  距离现在最近  未来
      const progress = that.competition.progress
      const curProgress = []
      progress.forEach((item, index) => {
        const pStartUtcDate = item.startUtcDate
        const pStartLocalStr = new Date(pStartUtcDate).getTime()
        item['pStartLocalStr'] = pStartLocalStr

        if (that.currentTime > pStartLocalStr) {
          curProgress.push(Number(pStartLocalStr))
        } else {
          item['progressStatus'] = '2'
        }
      })

      // 打散获取最大值
      const mathMax = Math.max(...curProgress)

      progress.forEach((item, index) => {
        if (item.pStartLocalStr == mathMax) {
          item['progressStatus'] = '1'
        } else if (item.progressStatus != 2) {
          item['progressStatus'] = '0'
        }
      })
      //   }else{
      //     if(res.code == 404){
      //       message.error(res.message)
      //       this.$router.push({path:'/competition'});
      //     }
      //   }
      // });
    },

    toSignFun(templateId, categoryId, competitionId, teamsUsers, teams, parentId, zoneId) {
      const that = this
      that.curInfoArray.templateId = templateId
      that.curInfoArray.categoryId = categoryId // 当前赛题分类id
      that.curInfoArray.competitionId = competitionId // 当前赛题id
      that.curInfoArray.teamsUsers = teamsUsers // 当前赛题限制成员数
      that.curInfoArray.teams = teams
      that.curInfoArray.parentId = parentId
      that.curInfoArray.zoneId = zoneId
      if (Cookies.get('DEFAULT_LANG') != '' && Cookies.get('DEFAULT_LANG') != undefined) {
        that.curInfoArray.currentLanguge = Cookies.get('DEFAULT_LANG')
      } else {
        that.curInfoArray.currentLanguge = 'zh_CN'
      }

      // this.formteen=true

      let token = ''
      const saitiAccessInfo = Cookies.get('saiti_access_info')
      if (saitiAccessInfo != '' && saitiAccessInfo != undefined && saitiAccessInfo != null) {
        token = JSON.parse(saitiAccessInfo).token
      }
      if (token != '' && token != undefined) {
        that.formxieyi = true
      } else {
        that.handleLogin()
        // message.error(that.globalDataArray.siteTemplateLanguage.please_login)
      }
    },
    // 选择报名方式
    signtypeFun(res) {
      const that = this
      // 自定义事件，把item传给父组件,发射事件
      if (res == '1') { // 报名
        if (that.curInfoArray.templateId == '01') { // 成人
          that.formsign = true
        } else {
          that.formteen = true // 青年
        }
      }

      if (res == '2') { // 加入团队
        that.formapply = true
      }
    },

    formsignFun(num) {
      const that = this
      // console.log(num)
      if (num == '1') { // 立即报名
        message.success(that.globalDataArray.siteTemplateLanguage.competition_signup_success_tips)
        // setTimeout(function(){
        //   location.reload();
        // },1000)
        // 报名成功改变按钮状态
        that.saitiStatusCont.isSignup = '1'

        if (that.saitiStatusCont.teams != null && that.saitiStatusCont.teams != '') {
          that.saitiStatusCont.teams += 1
        } else {
          that.saitiStatusCont.teams = '1'
        }
      }

      if (num == '2') { // 取消、关闭

      }
    },

    formapplyFun(res) {
      const that = this
      if (res.clickType == '1') { // 加入
        that.teamArray = res
        that.formmsg = true
        // console.log(that.teamArray)
      }

      if (res.clickType == '2') { // 取消、关闭
        // that.formmsg=false
      }
    },

    formmsgFun(res) {
      const that = this
      if (res == '1') { // 确认
        // this.formapply=true

        // 报名成功改变按钮状态
        that.saitiStatusCont.isSignup = '2'
      }

      if (res == '2') { // 取消、关闭
        this.formapply = true
      }
    },

    formxieyiFun(res) {
      const that = this
      if (res == '1') { // 同意
        this.signway = true
      }

      if (res == '2') { // 取消、关闭

      }
    },

    handleLogin() {
      const redirectUrl = encodeURIComponent(location.href)
      Cookies.set('atom_redirect', redirectUrl, { domain: '.atomgit.com', expires: 180, path: '/', secure: true })
      location.href = this.logAuthUrl
    },

    goTopFun() {
      if (typeof document === 'object') {
        const scrollToptimer = setInterval(function() {
        // console.log("定时循环回到顶部");
          var top = document.body.scrollTop || document.documentElement.scrollTop
          var speed = top / 4
          document.documentElement.scrollTop -= speed
          if (top == 0) {
            clearInterval(scrollToptimer)
          }
        }, 30)
      }
    }
  },

  metaInfo() {
    let title = this.globalDataArray.siteInfo.title
    let description = this.globalDataArray.siteInfo.description
    let keywords = this.globalDataArray.siteInfo.keywords
    if (this.$store.state.sitiInfo != '' && this.$store.state.sitiInfo != null) {
      if (this.$store.state.sitiInfo.title != '' && this.$store.state.sitiInfo.title != null) {
        title = this.$store.state.sitiInfo.title + '-' + this.globalDataArray.siteInfo.title
      }
      if (this.$store.state.sitiInfo.description != '' && this.$store.state.sitiInfo.description != null) {
        description = this.$store.state.sitiInfo.description
      }
      if (this.$store.state.sitiInfo.keywords != '' && this.$store.state.sitiInfo.keywords != null) {
        keywords = this.$store.state.sitiInfo.keywords
      }
    }
    return {
      title: title,
      meta: [
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'keywords', name: 'keywords', content: keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/problem_des.css'
</style>
